import { render, staticRenderFns } from "./base.vue?vue&type=template&id=34b0e35f&"
import script from "./base.vue?vue&type=script&lang=ts&"
export * from "./base.vue?vue&type=script&lang=ts&"
import style0 from "./base.vue?vue&type=style&index=0&id=34b0e35f&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomHeader: require('/var/www/prod/partners/user-front/components/CustomHeader.vue').default})
