import axios from 'axios';
import { ApiUrls } from '~/consts/ApiUrls';
import { BaseService } from '~/services/BaseService';
import { Config } from '~/consts/Config';
export class AuthService extends BaseService {
    constructor(
    ) {
        super();
        console.log(process.env.AAA);
        console.log(process.env.AAA);

    }

    loginApi(data: any)
    {
        return axios.post(ApiUrls.authLogin(), data);
    }

    getMyDataApi()
    {
        return axios.get(ApiUrls.authGetMyData(),{ headers: this.getHeaders(), data: {}});
    }

    updateApi(data: any) {
        return axios.put(ApiUrls.authUpdate(), data, { headers: this.getHeaders() });
    }

    uploadImageApi(createData: any[] | null = null, deleteData: any[] | null = null) {
        let data: any = {};
        if (createData !== null && createData.length !== 0) {
            data.create_upload_files = createData[0];
        }
        if (deleteData !== null && deleteData.length !== 0) {
            data.delete_upload_files = deleteData;
        }
        return axios.post(ApiUrls.authImage(), data, { headers: this.getHeaders() });
    }

    setAccessToken(accessToken: string): boolean
    {
        try {
            localStorage.setItem('access_token', accessToken);
            return true;
        } catch (error) {
            return false;
        }
    }

    setMyData(authData: any) {
        try {
            localStorage.setItem('auth_data', JSON.stringify(authData));
            return true;
        } catch (error) {
            return false;
        }
    }

    getMyData(): any {
        try {
            const authData = localStorage.getItem('auth_data');
            if (authData !== null) {
                return JSON.parse(authData);
            }
            return null;
        } catch (error) {
            return null;
        }
    }

    isLogin (): boolean
    {
        try {
            const accessTokrn = localStorage.getItem('auth_data')
            if (accessTokrn !== null) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    isHeadOffice(): boolean
    {
        try {
            const myData = this.getMyData();
            console.log('myData', myData);
            return myData.is_head_office;
        } catch (error) {
            return false;
        }
    }

    logout(): boolean
    {
        try {
            localStorage.removeItem('access_token');
            localStorage.removeItem('auth_data');
            return true;
        } catch (error) {
            return false;
        }
    }
}
