export const CustomFooter = () => import('../../components/CustomFooter.vue' /* webpackChunkName: "components/custom-footer" */).then(c => wrapFunctional(c.default || c))
export const CustomHeader = () => import('../../components/CustomHeader.vue' /* webpackChunkName: "components/custom-header" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const ShowStoreUploadFiles = () => import('../../components/ShowStoreUploadFiles.vue' /* webpackChunkName: "components/show-store-upload-files" */).then(c => wrapFunctional(c.default || c))
export const ShowUpdateUploadFiles = () => import('../../components/ShowUpdateUploadFiles.vue' /* webpackChunkName: "components/show-update-upload-files" */).then(c => wrapFunctional(c.default || c))
export const ShowUploadFiles = () => import('../../components/ShowUploadFiles.vue' /* webpackChunkName: "components/show-upload-files" */).then(c => wrapFunctional(c.default || c))
export const StoreUploadFile = () => import('../../components/StoreUploadFile.vue' /* webpackChunkName: "components/store-upload-file" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../components/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const ValidateError = () => import('../../components/ValidateError.vue' /* webpackChunkName: "components/validate-error" */).then(c => wrapFunctional(c.default || c))
export const LayoutsCustomFooter = () => import('../../components/_layouts/CustomFooter.vue' /* webpackChunkName: "components/layouts-custom-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsCustomHeader = () => import('../../components/_layouts/CustomHeader.vue' /* webpackChunkName: "components/layouts-custom-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
