
import Vue from 'vue';
// import CustomHeader from '../components/layouts/CustomHeader.vue';
// import CustomFooter from '~/components/layouts/CustomFooter.vue';

export default Vue.extend({
    components: {
    //   CustomHeader, CustomFooter
    }
});
