import axios from 'axios';
import { Config } from '~/consts/Config';
import { ApiUrls } from '~/consts/ApiUrls';
import { FileApiUrls } from '~/consts/FileApiUrls';
import { BaseService } from '~/services/BaseService';

export class FileAuthService extends BaseService {
    private static token: string = '';
    private static limitedUnixTime: number = 0;

    constructor(
    ) {
        super();
    }

    getToken(): any {
        return FileAuthService.token;
    }

    setToken(token: any): void {
        FileAuthService.token = token;
    }

    isToken(): boolean
    {
        return this.getToken() !== '';
    }

    deleteToken(): void {
        FileAuthService.token = '';
    }

    getlimitedUnixTime(): any {
        return FileAuthService.limitedUnixTime;
    }

    setlimitedUnixTime(limitedUnixTime: number): void {
        FileAuthService.limitedUnixTime = limitedUnixTime;
    }

    deletelimitedUnixTime(): void {
        FileAuthService.limitedUnixTime = 0;
    }

    private getFileApiHeaders() {
        let heades = this.getBaseHeaders();
        heades.Authorization = this.getAccessToken();
        return heades;
    }

    async getTokenApi(): Promise<boolean>
    {
        return await axios.get(FileApiUrls.getAceessToken(), { params: {}, headers: this.getFileApiHeaders() })
            .then(response => {
                this.setToken(response.data.data.token);
                this.setlimitedUnixTime(response.data.data.limited_unix_time);
                return true;
            })
            .catch(error => {
                console.log(error.status);
                return false;
            });
    }

    checkLimitedAt(): boolean
    {
        const date = new Date();
        return (FileAuthService.limitedUnixTime - Config.tokenBufferSeconds) > Math.floor(date.getTime() / 1000);
    }

    async setTokenIfExpiredlimitedUnixTime(): Promise<void>
    {
        if (this.checkLimitedAt()) {
            return;
        }
        // const isSuccess = await this.getTokenApi();
        await this.getTokenApi();
    }

    getUrl(url: string): string
    {
        // await this.setTokenIfExpiredlimitedUnixTime();
        console.log(FileAuthService.token);
        if (url.indexOf('?') !== -1) {
            return url + '&token=' + FileAuthService.token;
        }
        return url + '?token=' + FileAuthService.token;
    }

}
