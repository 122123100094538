import { AuthService } from '../services/AuthService';

export default async function ({ $auth, store, route, redirect }) {
    if (route.path === '/auth/login' || route.path === '/auth/login/') {
        return;
    }
    try {
        const authService = new AuthService();
        const response = await authService.getMyDataApi();
        const ret = authService.setMyData(response.data.data);
        if (!ret) {
            console.log('ghjklkjhgfghjklkjhg');
            return redirect('/auth/login');
        }
    } catch (e) {
        console.log(e);
        return redirect('/auth/login');
    }
}
