import { Config } from '~/consts/Config';

export class ApiUrls {
    static authLogin(): string {
        return Config.baseApiUrl + 'auth/login';
    }

    static authGetMyData(): string {
        return Config.baseApiUrl + 'auth/getMyData';
    }

    static authUpdate(): string {
        return Config.baseApiUrl + 'auth/update';
    }

    static authImage(): string {
        return Config.baseApiUrl + 'auth/update/image';
    }

    static Work(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'works' + stringId;
    }

    static MyWorkGood(id: number): string {
        return Config.baseApiUrl + 'my-works' + '/' + String(id) + '/goods';
    }

    static Knowledge(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'knowledges' + stringId;
    }

    static MyknowledgeGood(id: number): string {
        return Config.baseApiUrl + 'my-knowledges' + '/' + String(id) + '/goods';
    }

    static MyKnowledgegGetUnreads(): string {
        return Config.baseApiUrl + 'my-knowledges' + '/getUnreads';
    }

    static Question(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'questions' + stringId;
    }

    static MyKnowledge(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'my-knowledges' + stringId;
    }

    static MyKnowledgeUploadFiles(id: number): string {
        return Config.baseApiUrl + 'my-knowledges/' + String(id) + '/uploadfiles';
    }

    static MyQuestion(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'my-questions' + stringId;
    }

    static MyQuestionUploadFiles(id: number): string {
        return Config.baseApiUrl + 'my-questions/' + String(id) + '/uploadfiles';
    }

    static MyQuestionAnswer(questionId: number, id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'my-questions/' + String(questionId) + '/answers' + stringId;
    }

    static MyQuestionAnswerUploadFiles(id: number): string {
        return Config.baseApiUrl + 'my-questions/answers/' + String(id) + '/uploadfiles';
    }

    static MyWork(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'my-works' + stringId;
    }

    static MyWorkComent(id: number): string {
        return Config.baseApiUrl + 'my-works' + '/' + String(id) + '/comments'
    }

    static MyWorkUploadFiles(id: number): string {
        return Config.baseApiUrl + 'my-works/' + String(id) + '/uploadfiles';
    }

    static MstCategory(id: number | null = null): string {
        const stringId = id === null ? '' : '/' + String(id);
        return Config.baseApiUrl + 'mst/categories' + stringId;
    }
}
