export class BaseService {
    constructor(
    ) {
    }

    getAccessToken()
    {
        return localStorage.getItem('access_token');
    }

    getBaseHeaders(): any {
        return {
            "Content-Type": "application/json"
        };
    }

    getHeaders(): any
    {
        let headers = this.getBaseHeaders();
        const accessToken = this.getAccessToken();
        headers.Authorization = 'Bearer ' + accessToken
        return headers;
    }

    getRandomString(num: number = 10): string
    {
        let S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from(crypto.getRandomValues(new Uint8Array(num))).map((n) => S[n % S.length]).join('');
    }
}
