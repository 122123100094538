import { FileAuthService } from '../services/FileAuthService';

export default async function ({ $auth, store, route, redirect }) {
    if (route.path === '/auth/login' || route.path === '/auth/login/') {
        return;
    }
    try {
        const fileAuthService = new FileAuthService();
        if(!fileAuthService.isToken()) {
           const a =  await fileAuthService.getTokenApi();
        }
    } catch (e) {
        console.log(e);
    }
}
