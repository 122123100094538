const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['fileAuthenticated'] = require('../middleware/fileAuthenticated.ts')
middleware['fileAuthenticated'] = middleware['fileAuthenticated'].default || middleware['fileAuthenticated']

middleware['isFooter'] = require('../middleware/isFooter.ts')
middleware['isFooter'] = middleware['isFooter'].default || middleware['isFooter']

export default middleware
