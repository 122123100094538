import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39d7002b = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _6ef3f1bf = () => interopDefault(import('../pages/my-work/index.vue' /* webpackChunkName: "pages/my-work/index" */))
const _78df3b2f = () => interopDefault(import('../pages/question/index.vue' /* webpackChunkName: "pages/question/index" */))
const _4067d8cc = () => interopDefault(import('../pages/work/index.vue' /* webpackChunkName: "pages/work/index" */))
const _282bbc88 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2a5cdcfb = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _1f5159a4 = () => interopDefault(import('../pages/auth/show.vue' /* webpackChunkName: "pages/auth/show" */))
const _367348ba = () => interopDefault(import('../pages/auth/update.vue' /* webpackChunkName: "pages/auth/update" */))
const _99ef3c96 = () => interopDefault(import('../pages/my-knowledge/store.vue' /* webpackChunkName: "pages/my-knowledge/store" */))
const _2826a13a = () => interopDefault(import('../pages/my-question/store.vue' /* webpackChunkName: "pages/my-question/store" */))
const _6fa1ef83 = () => interopDefault(import('../pages/my-work/index2.vue' /* webpackChunkName: "pages/my-work/index2" */))
const _03ef224e = () => interopDefault(import('../pages/my-work/store.vue' /* webpackChunkName: "pages/my-work/store" */))
const _de25dd5a = () => interopDefault(import('../pages/knowledge/_id.vue' /* webpackChunkName: "pages/knowledge/_id" */))
const _0c103d57 = () => interopDefault(import('../pages/question/_id.vue' /* webpackChunkName: "pages/question/_id" */))
const _42c396fc = () => interopDefault(import('../pages/work/_id.vue' /* webpackChunkName: "pages/work/_id" */))
const _adbfe5cc = () => interopDefault(import('../pages/my-knowledge/_id/update.vue' /* webpackChunkName: "pages/my-knowledge/_id/update" */))
const _2661c7a8 = () => interopDefault(import('../pages/my-question/_id/update.vue' /* webpackChunkName: "pages/my-question/_id/update" */))
const _c0f277be = () => interopDefault(import('../pages/my-work/_id/update.vue' /* webpackChunkName: "pages/my-work/_id/update" */))
const _59fa3208 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/knowledge",
    component: _39d7002b,
    name: "knowledge"
  }, {
    path: "/my-work",
    component: _6ef3f1bf,
    name: "my-work"
  }, {
    path: "/question",
    component: _78df3b2f,
    name: "question"
  }, {
    path: "/work",
    component: _4067d8cc,
    name: "work"
  }, {
    path: "/auth/login",
    component: _282bbc88,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _2a5cdcfb,
    name: "auth-logout"
  }, {
    path: "/auth/show",
    component: _1f5159a4,
    name: "auth-show"
  }, {
    path: "/auth/update",
    component: _367348ba,
    name: "auth-update"
  }, {
    path: "/my-knowledge/store",
    component: _99ef3c96,
    name: "my-knowledge-store"
  }, {
    path: "/my-question/store",
    component: _2826a13a,
    name: "my-question-store"
  }, {
    path: "/my-work/index2",
    component: _6fa1ef83,
    name: "my-work-index2"
  }, {
    path: "/my-work/store",
    component: _03ef224e,
    name: "my-work-store"
  }, {
    path: "/knowledge/:id",
    component: _de25dd5a,
    name: "knowledge-id"
  }, {
    path: "/question/:id",
    component: _0c103d57,
    name: "question-id"
  }, {
    path: "/work/:id",
    component: _42c396fc,
    name: "work-id"
  }, {
    path: "/my-knowledge/:id?/update",
    component: _adbfe5cc,
    name: "my-knowledge-id-update"
  }, {
    path: "/my-question/:id?/update",
    component: _2661c7a8,
    name: "my-question-id-update"
  }, {
    path: "/my-work/:id?/update",
    component: _c0f277be,
    name: "my-work-id-update"
  }, {
    path: "/",
    component: _59fa3208,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
